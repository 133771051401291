<template>
  <div>
    <Section class="mb-4">
      <template #title>
        <div class="flex flex-wrap align-center gap-3">
          Address Information
          <MonitoringButton v-if="addressData.isMonitoring && !selectedToken.address" />
          <gl-icon
            v-else
            v-popover:tooltip.top="'This report was generated by using the last 10 incoming transactions of the requested address. Add the address to the Monitoring to get a report based on the last 100 incoming transactions'"
            :height="24"
            name="def-report"
            :width="24"
          />
        </div>
      </template>
      <template #body>
        <div
          v-if="!hasTxs && !addressDataLoading"
          class="wallet-address-wrap pa-2"
        >
          There are no transactions for this address
        </div>
        <div class="pa-2">
          <InfoBlock
            class="mb-4 mr-3"
            flex-second-column-two
            inline-view
            label="Wallet address"
            :loading="addressDataLoading"
            :value="`${(addressData && addressData.address) || address}`"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Type of the address"
            :loading="addressDataLoading"
            value="Token Contract"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Token"
            :loading="addressDataLoading"
            :value="selectedToken.symbol"
          >
            <template #icon>
              <GlImg
                v-if="selectedToken && selectedToken.icon"
                :src-val="selectedToken.icon"
              />
            </template>
          </InfoBlock>
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Token Balance"
            :loading="addressDataLoading"
            :rate="addressData && addressData.price && formatByPrice(addressData.price)"
            rate-time="current"
            :rate-timestamp="addressData && addressData.priceTimestamp"
            :value="addressData
              && addressData.balance
              && `${toComaSeparate(String(restrictNumberAfterComma(addressData.balance, 8)))} ${addressData.symbol || selectedToken.symbol}`"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Number of TXS"
            :loading="addressDataLoading"
            :value="(addressData && toComaSeparate(String(addressData.txCount))) || '0'"
          />
          <InfoBlock
            class="mb-4"
            flex-second-column-two
            inline-view
            label="Total tx sent"
            :loading="addressDataLoading"
            :value="(addressData && toComaSeparate(String(addressData.txOutputsCount))) || '0'"
          />
          <InfoBlock
            flex-second-column-two
            inline-view
            label="Total tx received"
            :loading="addressDataLoading"
            :value="(addressData && toComaSeparate(String(addressData.txInputsCount))) || '0'"
          />
        </div>
        <!-- <div
          v-if="featureAccess('ALERTS')"
          class="pa-2"
        >
          <div
            class="key mb-3 fs-14"
          >
            Profile Flags
          </div>
          <GlFlagsWrap
            :flags-data="addressData.flagsData"
            hide-score
            :loading="addressDataLoading"
            :max-count="2"
          />
        </div> -->
      </template>
    </Section>
    <Section class="mb-4">
      <template #title>
        <div> Owner Information </div>
      </template>
      <template #body>
        <div v-if="isOwnerExist(addressData)">
          <ReportSectionBody
            :data="addressData"
            :data-description="addressData.description"
            :entity-id="addressData.entityId"
            label-tag="Extra context tags towards the classification of the requested address"
            label-top="Owner / Contract Name"
            label-type="Type of entity that owns requested address"
            :owner-name="`${
              (addressData && addressData.owner) ||
              (contractData && contractData.owner) ||
              '--'
            } / ${
              (addressData &&
                addressData.contractInfo &&
                addressData.contractInfo.name) ||
              (contractData &&
                contractData.contractInfo &&
                contractData.contractInfo.name) ||
              '--'
            }`"
            :tag-meta-data="addressData.meta"
            :type-data="addressData.type"
          />

          <hr class="mt-2 mb-2 pale-grey-border">
          <EthContractEnsBlock
            :address-data="addressData"
            :contract-data="contractData"
          />
        </div>

        <div
          v-else
          class="pa-2"
        >
          Not identified
        </div>
      </template>
    </Section>
  </div>
</template>

<script>
// Components
import GlIcon from "@/components/gl-icon"
import GlImg from "@/components/gl-img.vue";
import Section from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/ReportSectionBody'
import MonitoringButton from '@/pages/report/components/MonitoringButton'
import EthContractEnsBlock from '@/pages/report/components/eth-section-body/EthContractEnsBlock'
import InfoBlock from '@/components/gl-info-block'
import GlFlagsWrap from '@/components/gl-flags-wrap';
// utils
import { isOwnerExist } from '@/utils/report-data-formatter'
import { toComaSeparate, restrictNumberAfterComma } from "@/utils/formatNumber";
import { formatByPrice } from "@/utils/format-by-price";
import {featureAccess} from "@/utils/accesses";

export default {
  name: 'EthSectionTokenContractInfo',
   components: {
    GlIcon,
    GlImg,
    MonitoringButton,
    Section,
    ReportSectionBody,
    InfoBlock,
    EthContractEnsBlock,
    GlFlagsWrap
  },
  props: {
    addressData: {
      type: Object,
      default: () => {},
    },
    contractData: {
      type: Object,
      default: () => {},
    },
    hasTxs: {
      type: Boolean,
      default: false,
    },
    addressDataLoading: {
      type: Boolean,
      default: false
    },
    address: {
      type: [String],
      default: '',
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ownerContractLabel() {
      return this.addressData.owner
    },
  },
  methods: {
    featureAccess,
    isOwnerExist,
    toComaSeparate,
    restrictNumberAfterComma,
    formatByPrice
  },
}
</script>
